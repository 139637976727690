import {
    OrdersSearchPaginationParams,
    Pagination,
    PaginationParams,
    SimpleSearchPaginationParams
} from "../core/services/utils";
import {http} from "../core/services/http";
import {
    IInstitution,
    IBook,
    INameId,
    XmasPackageListEntry,
    XmasPackageDetails,
    OrderListEntry, OrderDetails
} from "../core/interfaces";
import {UUID} from "node:crypto";


export const getAllXmasPackages = (params?: SimpleSearchPaginationParams) =>
    http.get<Pagination<XmasPackageListEntry>>('/api/dashboard/orders/xmas-packages', {
        params,
    })

export const getAllOrders = (params?: OrdersSearchPaginationParams) =>
    http.get<Pagination<OrderListEntry>>('/api/dashboard/orders', {
        params,
    })

export const getOrderDetails = (id: string) =>
    http.get<OrderDetails>(`/api/dashboard/orders/details/${id}`)

export const getOrderInvoiceUrl = (id: string) =>
    http.post<string>(`/api/dashboard/orders/${id}/generate-invoice`)

export const integrateTo2Invoices = (id: string) =>
    http.post(`/api/dashboard/orders/${id}/integrate-two-invoices`)


export const getXmasPackageDetails = (id: string) =>
    http.get<XmasPackageDetails>(`/api/dashboard/orders/xmas-packages/${id}`)

export const replaceBook = (id: string, bookInstanceId: string, bookId: string) =>
    http.post(`/api/dashboard/orders/xmas-packages/${id}/from/${bookInstanceId}/to/${bookId}`)

export const finalizePackage = (id: string) =>
    http.put(`/api/dashboard/orders/xmas-packages/${id}/finalize`)

export const finalizeOrder = (id: string) =>
    http.put(`/api/dashboard/orders/${id}/finalize`)

export const setAwbOnPackage = (id: string, awb: string) =>
    http.put(`/api/dashboard/orders/xmas-packages/${id}/awb/${awb}`)

export const setAwbOnOrder = (id: string, awb: string) =>
    http.put(`/api/dashboard/orders/${id}/awb/${awb}`)