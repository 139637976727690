'use client';
import React, {ChangeEvent, useCallback, useEffect, useRef, useState} from "react";
import {IInstitutionGroup, OrderDetails} from "../../core/interfaces";
import Loader from "../../core/components/Loader";
import {Link, useParams} from "react-router-dom";
import {ArrowLeft} from "@mui/icons-material";
import {
    finalizeOrder,
    finalizePackage,
    getOrderDetails,
    replaceBook, setAwbOnOrder,
    setAwbOnPackage
} from "../../services/orders";
import {Form} from "react-bootstrap";
import {map} from "lodash-es";
import Divider from '@mui/material/Divider';
import {Button} from "@mui/material";

export const OrderDetailsPage: React.FC = (): JSX.Element => {

    const {orderId} = useParams()
    const [loading, setLoading] = useState<boolean>(false)
    const [update, setUpdate] = useState<boolean>(true)
    const [awb, setAwb] = useState<string>('')
    const [orderDetails, setOrderDetails] = useState<OrderDetails>()
    const selection = useRef<HTMLFormElement>(null);

    const getData = useCallback(async () => {
        try {
            setLoading(true)
            const response = await getOrderDetails(orderId)
            setOrderDetails(response.data)
        } catch (e) {
            if (e instanceof Error) {
            } else {
                console.error(e)
            }
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        if (update) {
            getData()
            setUpdate(false);
        }
    }, [update])


    function onchangeBook(pkgId: string, instanceId: string, bookId: string) {
        replaceBook(pkgId, instanceId, bookId).then(value => {
            setUpdate(true);
            selection?.current?.reset();
        });
    }

    function finalize() {
        finalizeOrder(orderId)
            .then(value => {alert("Package finalized")})
            .finally(() => {setUpdate(true)});
    }

    function modifyAwb(e: ChangeEvent<HTMLInputElement>) {
        console.log(e.target.value);
        setAwb(e.target.value);
    }

    function triggerSetAwb() {
        setAwbOnOrder(orderId, awb)
            .then(value => {alert("AWB set")})
            .catch(reason => {alert("AWB set failed! Mai multe pachete in comanda.")})
            .finally(() => {setUpdate(true)});
    }

    return (
        <>
            <div
                style={{
                    padding: 20,
                    backgroundColor: '#F8F8F8',
                    position: 'relative',
                }}
            >
                {loading && <Loader />}
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 20,
                    }}
                    >
                    <h2>
                        <Link to={`/orders/list`}>
                            <ArrowLeft style={{backgroundColor: 'black', color: 'white', padding: '0px', width: '20px', height: '20px', textAlign: 'center', lineHeight: '1.1', borderRadius: '10px', marginRight: '10px'}}/>
                        </Link>
                        Order</h2>

                </div>
                <div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <div className="d-flex gap-2" style={{flexDirection: "column"}}>
                            <div className="row"><span className="">Order: {orderDetails?.orderDispNumber}</span></div>
                            <div className="row"><span className="">Order Note: {orderDetails?.orderNote}</span></div>
                            <div className="row"><span className="">Pret: {orderDetails?.fullPrice}</span></div>
                            <div className="row"><span className="">Transport: {orderDetails?.transportPrice}</span>
                            </div>
                            <div className="row"><span className="">Voucher Code: {orderDetails?.voucherCode}</span>
                            </div>
                            <div className="row"><span
                                className="">Voucher Value: {orderDetails?.voucherValue} {orderDetails?.percentage ? "%" : ""}</span>
                            </div>
                            <div className="row"><span
                                className="">Utilizator: {orderDetails?.userName} - {orderDetails?.userPhone}</span>
                            </div>
                            <div className="row"><span className="">Status: {orderDetails?.status}</span></div>
                            <div className="row"><span className="">Tip: {orderDetails?.type}</span></div>
                            <div className="row"><span className="">AWB: {orderDetails?.awb}</span></div>
                        </div>
                        {orderDetails?.deliveryAddress ?
                            <div className="d-flex gap-2" style={{flexDirection: "column"}}>
                                <div className="row"><span className=""><b>Adresă de livrare</b></span></div>

                                <div className="row"><span
                                    className="">{orderDetails?.deliveryAddress.firstName} {orderDetails?.deliveryAddress.lastName}</span>
                                </div>
                                <div className="row"><span className="">{orderDetails?.deliveryAddress.email}</span>
                                </div>
                                <div className="row"><span className="">{orderDetails?.deliveryAddress.phone}</span>
                                </div>
                                <div className="row"><span className="">{orderDetails?.deliveryAddress.address}</span>
                                </div>
                                <div className="row"><span className="">{orderDetails?.deliveryAddress.city}</span>
                                </div>
                                <div className="row"><span className="">{orderDetails?.deliveryAddress.county}</span>
                                </div>
                                <div className={"row"}><span className="">{orderDetails?.deliveryAddress.country}</span>
                                </div>
                                <div className="row"><span className="">{orderDetails?.deliveryAddress.postCode}</span>
                                </div>
                            </div>
                            : <></>}

                    </div>
                    {orderDetails?.packages.map((pkg, index) => (
                        <Form ref={selection}>
                            <Divider color="#0000FF"/>

                            <div className="d-flex" style={{flexDirection: "row"}}>
                                {pkg?.books.map((book, index) => (
                                    <div key={index} className="row">
                                        <div style={{margin: "auto"}}><img src={book?.selectedBook.url}
                                                                           style={{
                                                                               maxWidth: "250px",
                                                                               maxHeight: "250px"
                                                                           }}/>
                                        </div>
                                        <div className="row"><span className="">{book.selectedBook?.name}</span></div>
                                        <div className="row"><span className="">{book.selectedBook?.catalogPrice}</span>
                                        </div>
                                        <div className="row"><span className="">{book.selectedBook?.boughtPrice}</span>
                                        </div>
                                        <div style={{margin: "auto", minHeight: "150px"}}>
                                            {pkg?.status === 'AWB_ASSIGNED' ? <></> :
                                                <Form.Select className="d-flex flex-column mb-0"
                                                             defaultValue={'Select book'}
                                                             onChange={(e) => {
                                                                 onchangeBook(pkg.id, book.selectedBook.instanceId, e.target.value);
                                                             }}
                                                >
                                                    <option value={''} key={''}>
                                                        Select Book
                                                    </option>
                                                    {map(book.suggestions, (bookie) => (
                                                        <option id={bookie.bookId} key={bookie.bookId}
                                                                value={bookie.bookId}
                                                                selected={bookie.bookId === book.selectedBook.bookId}>
                                                            {bookie.name}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            }

                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Divider color="#0000FF"/>

                        </Form>

                    ))}
                    <div className="d-flex" style={{flexDirection: "row"}}>

                        {orderDetails?.books.map((book, index) => (
                            <>

                                <div key={index} className="row">
                                    <div style={{margin: "auto"}}><img src={book?.url}
                                                                       style={{
                                                                           maxWidth: "250px",
                                                                           maxHeight: "250px"
                                                                       }}/>
                                    </div>
                                    <div className="row"><span className="">{book.name}</span></div>
                                    <div className="row"><span className="">Pret catalog: {book.catalogPrice}</span>
                                    </div>
                                    <div className="row"><span className="">Pret platit: {book.boughtPrice}</span>
                                    </div>

                                </div>
                            </>

                        ))}
                    </div>
                        <Divider color="#0000FF"/>

                        {orderDetails?.packages.some(pkg => pkg.status === 'PAID') ?
                            <>
                                <Button variant="contained" size="small" onClick={() => {
                                    finalize();
                                }}>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <i className="bi bi-arrow-right-square me-2 d-flex"/>
                                        <span>Mark as final</span>
                                    </div>
                                </Button></>
                            :
                            <></>
                        }

                        {orderDetails?.type === 'BAG' && orderDetails?.status === 'PAID' && orderDetails?.awb === null ?
                            <>
                                <input type="text" placeholder="awb" onChange={modifyAwb} value={orderDetails.awb}/>
                                <Button variant="contained" size="small" onClick={() => {
                                    triggerSetAwb();
                                }}>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <i className="bi bi-arrow-right-square me-2 d-flex"/>
                                        <span>Set AWB</span>
                                    </div>
                                </Button></>
                            :
                            <></>
                        }
                        {orderDetails?.subscription ?
                            <>
                                <div>
                                    <div className="row"><span
                                        className="">Subscription Period: {orderDetails?.subscription.startDate} - {orderDetails?.subscription.endDate}</span>
                                    </div>
                                    <div className="row"><span
                                        className="">Payment for interval: {orderDetails?.subscription.payment.intervalStart} - {orderDetails?.subscription.payment.intervalEnd}</span>
                                    </div>
                                    <div className="row"><span
                                        className="">Amount: {orderDetails?.subscription.payment.amount}</span></div>
                                    <div className="row"><span
                                        className="">Platit: {orderDetails?.subscription.payment.paid ? "DA" : "NU"}</span>
                                    </div>
                                </div>
                            </>
                            :
                            <></>
                        }
                    </div>

                </div>
            </>
            )
            }